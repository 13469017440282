export const isBrandPCLocs = () => {
  const rootDomain: string[] = window.location.hostname.split('.');
  return rootDomain[rootDomain.length - 2] !== 'lockncharge';
};

export const getBrandName = () => {
  return isBrandPCLocs() ? 'PC Locs' : 'LocknCharge';
};

export const getBrandSupportLink = () => {
  return isBrandPCLocs()
    ? 'https://support.pclocs.com.au/links/cloud-support'
    : 'https://support.lockncharge.com/links/cloud-support';
};
